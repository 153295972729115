import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import { basePath } from "../variables";

export interface IEvaluation {
  evaluationId: number;
  evaluationType: string;
  evaluationDate: Date;
  score: number;
  studentId: number;
  driverLicense: string;
}

export interface IEvaluationInfo {
  errors: Array<string>;
  evaluations: Array<IEvaluation>;
}

@Module
export default class EvaluationModule
  extends VuexModule
  implements IEvaluationInfo
{
  errors = [];
  evaluations: Array<IEvaluation> = [];

  /**
   * Get config from layout config
   * @returns {function(path, defaultValue): *}
   */
  get getErros(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  async [Mutations.SET_EVALUATIONS](evaluations) {
    this.evaluations = evaluations;
  }

  @Action({ rawError: true })
  async [Actions.GET_EVALUATIONS]() {
    store.commit(Mutations.SET_LOADING_ON);
    const selectedStudentId: any = localStorage.getItem("selectedStudentId");
    if (selectedStudentId) {
      ApiService.setHeader();
      const response = await ApiService.vueInstance.axios.get(
        basePath + "/evaluations?studentId=" + selectedStudentId
      );
      store.commit(Mutations.SET_EVALUATIONS, response.data);
    }
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Action({ rawError: true })
  async [Actions.ADD_EVALUATION](evaluation) {
    return new Promise<void>((resolve, reject) => {
      const selectedStudentId: any = localStorage.getItem("selectedStudentId");
      if (selectedStudentId) {
        if (evaluation) {
          store.commit(Mutations.SET_LOADING_ON);
          ApiService.setHeader();
          ApiService.vueInstance.axios
            .post(
              basePath + "/evaluations?studentId=" + selectedStudentId,
              evaluation
            )
            .then(({ data }) => {
              store.commit(
                Mutations.SET_EVALUATIONS,
                this.evaluations.concat([data])
              );
              store.commit(Mutations.SET_LOADING_OFF);
              resolve(data);
            })
            .catch(({ response }) => {
              this.context.commit(Mutations.SET_ERROR, response.data.errors);
              store.commit(Mutations.SET_LOADING_OFF);
              reject();
            });
        }
      }
    });
  }
}
