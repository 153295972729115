import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import { basePath } from "../variables";

export interface IDocument {
  id: number;
  documentName: string;
  documenttype: string;
  studentId: number;
}

export interface IDocumentInfo {
  errors: Array<string>;
  documents: Array<IDocument>;
}

@Module
export default class DocumentModule
  extends VuexModule
  implements IDocumentInfo
{
  errors = [];
  documents: Array<IDocument> = [];

  /**
   * Get config from layout config
   * @returns {function(path, defaultValue): *}
   */
  get getErros(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  async [Mutations.SET_FILES](documents) {
    this.documents = documents;
  }

  @Action({ rawError: true })
  async [Actions.GET_FILES]() {
    store.commit(Mutations.SET_LOADING_ON);
    const selectedStudentId: any = localStorage.getItem("selectedStudentId");
    if (selectedStudentId) {
      ApiService.setHeader();
      const response = await ApiService.vueInstance.axios.get(
        basePath + "/documents/" + selectedStudentId
      );

      if (response.data) {
        const documentsToPush: Array<IDocument> = response.data.map(
          (document) => {
            return {
              ...document,
              studentId: document.student.studentId,
              link: `${basePath}/documents/getpdf/${document.fileName}/${document.student.studentId}`,
            };
          }
        );

        store.commit(Mutations.SET_FILES, documentsToPush);
      }
    }
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Action({ rawError: true })
  async [Actions.ADD_FILE](document) {
    return new Promise<void>((resolve, reject) => {
      const selectedStudentId: any = localStorage.getItem("selectedStudentId");
      if (selectedStudentId) {
        if (document.value) {
          const formData = new FormData();
          formData.append("Document", document.value);
          formData.append("Student", selectedStudentId);
          const axiosConfig = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          ApiService.setHeader();
          ApiService.vueInstance.axios
            .post(basePath + "/documents/document", formData, axiosConfig)
            .then(({ data }) => {
              console.log(this.documents);

              store.commit(Mutations.SET_FILES, this.documents.concat([data]));

              console.log(this.documents);
              resolve(data);
            })
            .catch(({ response }) => {
              this.context.commit(Mutations.SET_ERROR, response.data.errors);
              reject();
            });
        }
      }
    });
  }
}
