import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "..";
import { basePath } from "../variables";
import { IScheduledClass, Session } from "./IScheduleClass";

export interface IStudent {
  studentId?: number;
  fullName?: string;
  email?: string;
  paymentMethod?: string;
  fullAddress?: string;
  scheduledStartDate?: Date;
  step?: string;
  status?: string;
  state?: string;
  phoneNumber?: string;
  dob?: Date;
  permitNumber?: string;
  module?: string;
  accountType?: string;
  contractNumber?: string;
  scheduledClasses?: Array<IScheduledClass>;
  picture?: any;
  attendances?: IAttendance[];
}

export interface IAttendance {
  attendanceId: number;
  session?: Session;
  attended?: boolean;
  latestTimestamp?: Date;
}
export interface IStudentsInfo {
  errors: Array<string>;
  students: Array<IStudent>;
}

@Module
export default class StudentModule extends VuexModule implements IStudentsInfo {
  errors = [];
  students: Array<IStudent> = [];
  selectedStudent: IStudent = {};
  truckPackages = [
    { templateId: 2, description: "14 hours" },
    { templateId: 3, description: "18 hours" },
    { templateId: 4, description: "20 hours" },
    { templateId: 5, description: "26 hours" },
  ];

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;

    /**
     * Get students from DB
     * @returns array
     */
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  async [Mutations.SET_STUDENTS](students) {
    this.students = students;
  }

  @Action({ rawError: true })
  async [Actions.GET_STUDENTS]() {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.get(
      basePath + "/students"
    );
    store.commit(Mutations.SET_STUDENTS, response.data);
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Action({ rawError: true })
  [Actions.ADD_STUDENT]({ student }) {
    return new Promise<void>((resolve, reject) => {
      store.commit(Mutations.SET_LOADING_ON);
      let formData;
      if (student.file) {
        formData = new FormData();
        console.log("student.file", student.file);
        formData.append("File", student.file);
        delete student.file;
        console.log("student", student);
        formData.append("Student", JSON.stringify(student));

        const axiosConfig = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        ApiService.vueInstance.axios
          .post(basePath + "/students/file", formData, axiosConfig)
          .then(({ data }) => {
            store.commit(Mutations.SET_STUDENTS, this.students.concat([data]));
            store.commit(Mutations.SET_LOADING_OFF);
            resolve(data);
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
            store.commit(Mutations.SET_LOADING_OFF);
            reject();
          });
      } else {
        ApiService.vueInstance.axios
          .post(basePath + "/students", student)
          .then(({ data }) => {
            store.commit(Mutations.SET_STUDENTS, this.students.concat([data]));
            store.commit(Mutations.SET_LOADING_OFF);
            resolve(data);
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
            store.commit(Mutations.SET_LOADING_OFF);
            reject();
          });
      }
    });
  }

  @Action({ rawError: true })
  [Actions.MODIFY_STUDENT](data) {
    return new Promise<void>((resolve, reject) => {
      store.commit(Mutations.SET_LOADING_ON);
      ApiService.vueInstance.axios
        .put(`${basePath}/students/${data.studentId}`, {
          studentId: data.studentId,
          fullName: data.fullName,
          email: data.email,
          fullAddress: data.fullAddress,
          phoneNumber: data.phoneNumber,
        })
        .then(({ data }) => {
          store.commit(
            Mutations.SET_STUDENTS,
            this.students
              .filter((x) => x.studentId !== data.studentId)
              .concat([data])
          );
          store.commit(Mutations.SET_SELECTED_STUDENT, data);
          store.commit(Mutations.SET_LOADING_OFF);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          store.commit(Mutations.SET_LOADING_OFF);
          reject();
        });
    });
  }

  @Mutation
  async [Mutations.SET_SELECTED_STUDENT](student) {
    this.selectedStudent = student;
    if (this.selectedStudent.studentId)
      localStorage.setItem(
        "selectedStudentId",
        this.selectedStudent.studentId.toString()
      );
  }

  @Action
  async [Actions.GET_STUDENT]() {
    store.commit(Mutations.SET_LOADING_ON);
    let selectedStudentId: any = localStorage.getItem("selectedStudentId");
    selectedStudentId = selectedStudentId ? parseInt(selectedStudentId) : 1;

    //   const filteredStudent = this.students.filter(student => student.studentId == selectedStudentId);
    //   if (filteredStudent.length === 0) {
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.get(
      `${basePath}/students/${selectedStudentId}`
    );
    store.commit(Mutations.SET_SELECTED_STUDENT, response.data);

    //   }
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Action
  async [Actions.TOGGLE_STUDENT_STATE](id) {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.post(
      `${basePath}/students/toggle-state/${id}`
    );

    let studentsTemp = this.students.filter(
      (student) => student.studentId !== id
    );
    studentsTemp = studentsTemp.concat([response.data]);

    if (studentsTemp.length > 1) {
      studentsTemp = studentsTemp.sort((a, b) => {
        if (a.studentId && b.studentId) return a.studentId - b.studentId;
        else return 1;
      });
    }
    store.commit(Mutations.SET_STUDENTS, studentsTemp);
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Action
  async [Actions.GENERATE_TRUCK_SCHEDULE](form) {
    store.commit(Mutations.SET_LOADING_ON);
    const { teacherId, firstClassDate, studentId } = form;
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.post(
      `${basePath}/schedules/generate/truck?` +
        `studentId=${studentId}&` +
        `teacherId=${teacherId}&` +
        `firstClassDate=${firstClassDate.toISOString().split("T")[0]}`
    );

    store.dispatch(Actions.GET_STUDENT);
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Action
  async [Actions.TOGGLE_STUDENT_ATTENDANCE]({ studentId, sessionName }) {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.post(
      `${basePath}/students/${studentId}/attend/${sessionName}`
    );

    store.dispatch(Actions.GET_GROUP);
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Action
  async [Actions.REMOVE_STUDENT_FROM_GROUP]({ studentId, cohortId }) {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.post(
      `${basePath}/students/${studentId}/removeFromCohort/${cohortId}`
    );

    store.dispatch(Actions.GET_GROUP);
    store.dispatch(Actions.GET_STUDENTS);
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Action
  async [Actions.ADD_STUDENT_TO_GROUP]({ studentId, firstClassDate, group }) {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response: any = await ApiService.vueInstance.axios.post(
      `${basePath}/schedules/addStudentToCohort?` +
        `studentId=${studentId}&` +
        `cohortId=${group.cohortId}&` +
        `templateId=${group.template.templateId}&` +
        `firstClassDate=${firstClassDate.toISOString().split("T")[0]}`
    );

    store.dispatch(Actions.GET_GROUP);
    store.dispatch(Actions.GET_STUDENTS);
    store.commit(Mutations.SET_LOADING_OFF);
  }
}
