import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import store from "..";
import { basePath } from "../variables";

export interface ISmsNotification {
  id: number;
  messageObject: string;
  sentTo: string;
  timestamp: Date;
  status: string;
  scheduledDate: Date;
}

export interface ISmsNotificationInfo {
  errors: Array<string>;
  smsNotifications: Array<ISmsNotification>;
}

@Module
export default class SMSNotificationModule
  extends VuexModule
  implements ISmsNotificationInfo
{
  smsNotifications: Array<ISmsNotification> = [];
  errors = [];

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  async [Mutations.SET_SMS_NOTIFICATIONS](smsNotifications) {
    this.smsNotifications = smsNotifications;
  }

  @Action({ rawError: true })
  async [Actions.GET_SMS_NOTIFICATIONS]() {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.get(
      basePath + "/notifications"
    );
    store.commit(Mutations.SET_SMS_NOTIFICATIONS, response.data);
    store.commit(Mutations.SET_LOADING_OFF);
  }
}
