import { Session } from "./IScheduleClass";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "..";
import { basePath } from "../variables";

export interface ITemplate {
  templateId?: number;
  templateSessions?: Session[];
  phases: IPhase[];
}

export interface IPhase {
  id: number;
  name: string;
  description: string;
  modules: IModule[];
  priority: number;
}

export interface IModule {
  id: number;
  name: string;
  description: string;
  sessions: Session[];
  priority: number;
}

@Module
export default class TemplateModule extends VuexModule implements ITemplate {
  phases: IPhase[] = [];
  templateSessions?: Session[];
  errors = [];

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;

    /**
     * Get Groups from DB
     * @returns array
     */
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Action
  async [Actions.GET_TEMPLATE](templateId: number) {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.get(
      basePath + "/templates/" + templateId
    );
    store.commit(Mutations.SET_PHASES, response.data.phases);
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Mutation
  async [Mutations.SET_PHASES](phases) {
    this.phases = phases;
    store.commit(
      Mutations.SET_TEMPLATE_SESSIONS,
      TemplateModule.templateToSessions({
        phases: this.phases,
      })
    );
  }

  @Mutation
  async [Mutations.SET_TEMPLATE_SESSIONS](templateSessions) {
    this.templateSessions = templateSessions;
  }

  @Mutation
  async [Mutations.RESET_TEMPLATE_SESSIONS]() {
    this.templateSessions = TemplateModule.templateToSessions({
      phases: this.phases,
    });
  }

  @Mutation
  async [Actions.GET_TEMPLATE_SESSIONS]() {
    return this.templateSessions;
  }

  static templateToSessions = (template: ITemplate): Session[] => {
    const sortedPhases = template.phases.sort((a, b) =>
      a.priority > b.priority ? 1 : -1
    );

    const sessions = sortedPhases.reduce(
      (phaseAccumulator: Array<any>, phase: IPhase) => {
        const sortedModules = phase.modules.sort((a, b) =>
          a.priority > b.priority ? 1 : -1
        );

        const a = sortedModules.reduce(
          (moduleAccumulator: Array<any>, module: IModule) => {
            const sortedSessions = module.sessions.sort((a, b) =>
              a.priority > b.priority ? 1 : -1
            );

            const b = sortedSessions.reduce(
              (sessionsAccumulator: Array<any>, session: Session) => {
                return sessionsAccumulator.concat(session);
              },
              []
            );

            return moduleAccumulator.concat(b);
          },
          []
        );
        return phaseAccumulator.concat(a);
      },
      []
    );

    return sessions;
  };
}
