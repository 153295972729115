import { createLogger, createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import StudentModule from "@/store/modules/StudentModule";
import FileModule from "@/store/modules/FileModule";
import EvaluationModule from "@/store/modules/EvaluationModule";
import GroupModule from "@/store/modules/GroupModule";
import TeacherModule from "@/store/modules/TeacherModule";
import SMSNotificationModule from "@/store/modules/SmsNotificationModule";
import DashboardModule from "@/store/modules/DashboardModule";
import ScheduledClassesModule from "@/store/modules/ScheduledClassesModule";
import TemplateModule from "@/store/modules/TemplateModule";
import LoadingModule from "@/store/modules/LoadingModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    StudentModule,
    GroupModule,
    TeacherModule,
    ScheduledClassesModule,
    FileModule,
    EvaluationModule,
    SMSNotificationModule,
    DashboardModule,
    TemplateModule,
    LoadingModule,
  },
  plugins: [createLogger()],
});

export default store;
