import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { parse } from "@intlify/message-resolver";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "..";
import { basePath } from "../variables";
import { IStudent } from "./StudentModule";
import { ITeacher } from "./TeacherModule";
import { ITemplate } from "./TemplateModule";

export interface IGroup {
  cohortId?: number;
  name?: string;
  theoryTeacher?: ITeacher;
  drivingTeacher?: ITeacher;
  firstClassDate?: Date;
  students?: Array<IStudent>;
  state?: string;
  template?: ITemplate;
}

export interface IGroupsInfo {
  errors: Array<string>;
  groups: Array<IGroup>;
  selectedGroup: IGroup;
}

@Module
export default class GroupModule extends VuexModule implements IGroupsInfo {
  errors = [];
  groups: Array<IGroup> = [];
  selectedGroup: IGroup = { students: [] };

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;

    /**
     * Get Groups from DB
     * @returns array
     */
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  async [Mutations.SET_GROUPS](groups) {
    this.groups = groups;
  }

  @Action({ rawError: true })
  async [Actions.GET_GROUPS]() {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.get(
      basePath + "/cohorts"
    );
    store.commit(Mutations.SET_GROUPS, response.data);
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Action({ rawError: true })
  [Actions.ADD_GROUP](data) {
    return new Promise<void>((resolve, reject) => {
      store.commit(Mutations.SET_LOADING_ON);
      const studentIds = data.students.toString();
      ApiService.setHeader();
      ApiService.vueInstance.axios
        .post(
          `${basePath}/cohorts?theoryTeacherId=${data.theoryTeacherId}&drivingTeacherId=${data.theoryTeacherId}&studentIds=${studentIds}`,
          {
            name: data.name,
            firstClassDate: data.firstClassDate,
          }
        )
        .then(({ data }) => {
          store.commit(Mutations.SET_GROUPS, this.groups.concat([data]));
          store.dispatch(Actions.GET_STUDENTS);
          store.commit(Mutations.SET_LOADING_OFF);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          store.commit(Mutations.SET_LOADING_OFF);
          reject();
        });
    });
  }

  @Mutation
  async [Mutations.SET_SELECTED_GROUP](group) {
    this.selectedGroup = group;
    if (this.selectedGroup.cohortId)
      localStorage.setItem(
        "selectedGroupId",
        this.selectedGroup.cohortId.toString()
      );
  }

  @Action
  async [Actions.GET_GROUP]() {
    store.commit(Mutations.SET_LOADING_ON);
    let selectedGroupId: any = localStorage.getItem("selectedGroupId");
    selectedGroupId = selectedGroupId ? parseInt(selectedGroupId) : 1;

    // const filteredGroup = this.groups.filter(group => group.cohortId == selectedGroupId);
    // if (filteredGroup.length === 1) {
    //   store.commit(Mutations.SET_SELECTED_GROUP, filteredGroup[0]);
    // }
    // else {
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.get(
      `${basePath}/cohorts/${selectedGroupId}`
    );
    store.commit(Mutations.SET_SELECTED_GROUP, response.data);
    // }
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Action
  async [Actions.TOGGLE_GROUP_STATE](id) {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.post(
      `${basePath}/cohorts/toggle-state/${id}`
    );

    let groupsTemp = this.groups.filter((group) => group.cohortId !== id);
    groupsTemp = groupsTemp.concat([response.data]);

    if (groupsTemp.length > 1) {
      groupsTemp = groupsTemp.sort((a, b) => {
        if (a.cohortId && b.cohortId) return a.cohortId - b.cohortId;
        else return 1;
      });
    }
    store.commit(Mutations.SET_GROUPS, groupsTemp);
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Action
  async [Actions.MODIFY_GROUP](data) {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response: any = await ApiService.vueInstance.axios.put(
      `${basePath}/cohorts/${data.cohortId}`,
      {
        name: data.name,
        state: data.state,
      }
    );

    const modifiedGroup: IGroup = response.data;

    store.commit(
      Mutations.SET_GROUPS,
      this.groups
        .filter((x) => x.cohortId !== modifiedGroup.cohortId)
        .concat([modifiedGroup])
    );
    store.commit(Mutations.SET_SELECTED_GROUP, modifiedGroup);
    store.commit(Mutations.SET_LOADING_OFF);
  }
}
