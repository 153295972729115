enum Actions {
  // action types
  GET_SCHEDULED_CLASSES = "GET_SCHEDULED_CLASSES",
  GET_NEW_STUDENTS_LIST = "GET_NEW_STUDENTS_LIST",
  ADD_STUDENT = "addStudent",
  GET_STUDENTS = "getStudents",
  GET_EVALUATIONS = "getEvaluations",
  ADD_EVALUATION = "addEvaluation",
  GET_FILES = "getFiles",
  ADD_FILE = "addFile",
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  GET_GROUPS = "getGroups",
  ADD_GROUP = "addGroup",
  ADD_TEACHER = "ADD_TEACHER",
  GET_TEACHERS = "GET_TEACHERS",
  MODIFY_STUDENT = "MODIFY_STUDENT",
  GET_GROUP = "GET_GROUP",
  GET_STUDENT = "GET_STUDENT",
  TOGGLE_GROUP_STATE = "TOGGLE_GROUP_STATE",
  TOGGLE_STUDENT_STATE = "TOGGLE_STUDENT_STATE",
  GET_SMS_NOTIFICATIONS = "GET_SMS_NOTIFICATIONS",
  MODIFY_GROUP = "MODIFY_GROUP",
  GENERATE_TRUCK_SCHEDULE = "GENERATE_TRUCK_SCHEDULE",
  TOGGLE_STUDENT_ATTENDANCE = "TOGGLE_STUDENT_ATTENDANCE",
  REMOVE_STUDENT_FROM_GROUP = "REMOVE_STUDENT_FROM_GROUP",
  ADD_STUDENT_TO_GROUP = "ADD_STUDENT_TO_GROUP",
  UPDATE_SCHEDULED_CLASSES = "UPDATE_SCHEDULED_CLASSES",
  UPDATE_TEACHER_OF_SCHEDULED_CLASS = "UPDATE_TEACHER_OF_SCHEDULED_CLASS",
  GET_SELECTED_SCHEDULED_CLASS = "GET_SELECTED_SCHEDULED_CLASS",
  UPDATE_TEACHER_OF_SCHEDULED_CLASSES = "UPDATE_TEACHER_OF_SCHEDULED_CLASSES",
  GET_TEMPLATE = "GET_TEMPLATE",
  GET_TEMPLATE_SESSIONS = "GET_TEMPLATE_SESSIONS",
}

enum Mutations {
  // mutation types
  SET_SCHEDULED_CLASSES = "SET_SCHEDULED_CLASSES",
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  SET_STUDENTS = "setStudents",
  SET_FILES = "setFiles",
  SET_EVALUATIONS = "setEvaluations",
  SET_GROUPS = "setGroups",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_TEACHERS = "SET_TEACHERS",
  SET_SELECTED_GROUP = "SET_SELECTED_GROUP",
  SET_SELECTED_STUDENT = "SET_SELECTED_STUDENT",
  SET_SMS_NOTIFICATIONS = "SET_SMS_NOTIFICATIONS",
  SET_NEW_STUDENTS_LIST = "SET_NEW_STUDENTS_LIST",
  SET_SELECTED_SCHEDULED_CLASS = "SET_SELECTED_SCHEDULED_CLASS",
  SET_PHASES = "SET_PHASES",
  SET_TEMPLATE_SESSIONS = "SET_TEMPLATE_SESSIONS",
  RESET_TEMPLATE_SESSIONS = "RESET_TEMPLATE_SESSIONS",
  SET_LOADING_ON = "SET_LOADING_ON",
  SET_LOADING_OFF = "SET_LOADING_OFF",
}

export { Actions, Mutations };
