import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import store from "..";
import { basePath } from "../variables";

export interface IDashboardData {
  listOfNewlyRegisterdStudents: Array<number>;
}

@Module
export default class DashboardModule
  extends VuexModule
  implements IDashboardData
{
  listOfNewlyRegisterdStudents = [];
  listOfAllClasses = [];
  @Mutation
  async [Mutations.SET_NEW_STUDENTS_LIST](newStudentsList) {
    this.listOfNewlyRegisterdStudents = newStudentsList;
  }

  @Action({ rawError: true })
  async [Actions.GET_NEW_STUDENTS_LIST]() {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.get(
      basePath + "/dashboard/students"
    );
    store.commit(Mutations.SET_NEW_STUDENTS_LIST, response.data);
    store.commit(Mutations.SET_LOADING_OFF);
  }
}
