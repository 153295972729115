import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "..";
import { basePath } from "../variables";
import { IScheduledClass } from "./IScheduleClass";

export interface IScheduledClassesInfo {
  errors: Array<string>;
  scheduledClasses?: Array<IScheduledClass>;
  selectedScheduledClass?: IScheduledClass;
}

@Module
export default class ScheduledClassesModule
  extends VuexModule
  implements IScheduledClassesInfo
{
  errors = [];
  classes: Array<IScheduledClass> = [];
  selectedScheduledClass?: IScheduledClass;

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;

    /**
     * Get Groups from DB
     * @returns array
     */
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  async [Mutations.SET_SCHEDULED_CLASSES](classes) {
    this.classes = classes;
  }

  @Action
  async [Actions.GET_SCHEDULED_CLASSES]() {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.get(
      basePath + "/classes"
    );
    store.commit(Mutations.SET_SCHEDULED_CLASSES, response.data);
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Action
  async [Actions.UPDATE_SCHEDULED_CLASSES](scheduledClasses) {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.put(
      basePath + "/classes",
      scheduledClasses
    );
    store.commit(Mutations.SET_LOADING_OFF);
    alert("Scheduled class changed successfully!");
  }

  @Mutation
  async [Mutations.SET_SELECTED_SCHEDULED_CLASS](scheduledClass) {
    this.selectedScheduledClass = scheduledClass;
  }

  @Action
  async [Actions.GET_SELECTED_SCHEDULED_CLASS]() {
    return this.selectedScheduledClass;
  }

  @Action
  async [Actions.UPDATE_TEACHER_OF_SCHEDULED_CLASS](form) {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.put(
      `${basePath}/classes/${form.scheduledClass.scheduledClassId}/updateTeacher/${form.teacherId}`
    );
    store.dispatch(Actions.GET_GROUP);
    store.dispatch(Actions.GET_TEACHERS);
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Action
  async [Actions.UPDATE_TEACHER_OF_SCHEDULED_CLASSES](form) {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const relatedClasses = form.scheduledClass.relatedClasses.map((c) => {
      return c.extendedProps.scheduledClass;
    });

    const requestBody = [form.scheduledClass].concat(relatedClasses);

    const response = await ApiService.vueInstance.axios.put(
      `${basePath}/classes/updateTeacher/${form.teacherId}`,
      requestBody
    );
    store.dispatch(Actions.GET_GROUP);
    store.dispatch(Actions.GET_TEACHERS);
    store.commit(Mutations.SET_LOADING_OFF);
  }
}
