import { Mutations } from "@/store/enums/StoreEnums";
import { Module, VuexModule, Mutation } from "vuex-module-decorators";

export interface ILoading {
  isLoading?: boolean;
}

@Module
export default class LoadingModule extends VuexModule implements ILoading {
  isLoading: boolean | undefined = false;

  @Mutation
  async [Mutations.SET_LOADING_ON]() {
    this.isLoading = true;
  }

  @Mutation
  async [Mutations.SET_LOADING_OFF]() {
    this.isLoading = false;
  }
}
