import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "..";
import { basePath } from "../variables";
import { IGroup } from "./GroupModule";
import { IScheduledClass } from "./IScheduleClass";

export interface ITeacher {
  teacherId?: number;
  name?: string;
  groups?: Array<IGroup>;
  scheduledClasses?: Array<IScheduledClass>;
  startTimeRangeWeekday?: string;
  endTimeRangeWeekday?: string;
  startTimeRangeWeekend?: string;
  endTimeRangeWeekend?: string;
}

export interface ITeacherInfo {
  errors: Array<string>;
  teachers: Array<ITeacher>;
}

@Module
export default class TeacherModule extends VuexModule implements ITeacherInfo {
  errors = [];
  teachers: Array<ITeacher> = [];

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;

    /**
     * Get Groups from DB
     * @returns array
     */
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  async [Mutations.SET_TEACHERS](teachers) {
    this.teachers = teachers;
  }

  @Action
  async [Actions.GET_TEACHERS]() {
    store.commit(Mutations.SET_LOADING_ON);
    ApiService.setHeader();
    const response = await ApiService.vueInstance.axios.get(
      basePath + "/teachers"
    );
    store.commit(Mutations.SET_TEACHERS, response.data);
    store.commit(Mutations.SET_LOADING_OFF);
  }

  @Action
  [Actions.ADD_TEACHER](data) {
    return new Promise<void>((resolve, reject) => {
      store.commit(Mutations.SET_LOADING_ON);
      ApiService.setHeader();
      ApiService.vueInstance.axios
        .post(`${basePath}/teachers`, {
          name: data.name,
          startTimeRangeWeekday: data.startTimeRangeWeekday,
          endTimeRangeWeekday: data.endTimeRangeWeekday,
          startTimeRangeWeekend: data.startTimeRangeWeekend,
          endTimeRangeWeekend: data.endTimeRangeWeekend,
        })
        .then(({ data }) => {
          store.commit(Mutations.SET_TEACHERS, this.teachers.concat([data]));
          store.commit(Mutations.SET_LOADING_OFF);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          store.commit(Mutations.SET_LOADING_OFF);
          reject();
        });
    });
  }
}
